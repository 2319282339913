import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Loading from '../../../helpers/commons/loader';
import { wtGetFromLocalStorage } from '../../../helpers/commons/LocalStorage';
import { useNavigate } from 'react-router-dom';
import { AppConstants } from '../../../helpers/commons/constants';
import { capitalizeFirstLetter, getZodiacSign, wtFormatRoundedValue, wtLogOut } from '../../../helpers/commons/utils';
import { wtFormatDate } from '../../../helpers/commons/moment';
import Carousel from '../../Widgets/Carousel';

const { Text } = Typography;

const Level108Quiz = ({ quiz, currency, applyDiscount, checkoutClick }) => {
    const { t } = useTranslation('website');
    const navigate = useNavigate();
    const authTokenWebsite = wtGetFromLocalStorage('authTokenWebsite', '');
    const authUserWebsite = wtGetFromLocalStorage('authUserWebsite', null);
    const [zodiacSign, setZodiacSign] = useState('');
    const [partnerZodiacSign, setPartnerZodiacSign] = useState('');
    const [genderText, setGenderText] = useState('');
    const [genderTextPartner, setGenderTextPartner] = useState('');
    const [placeOfBirth, setPlaceOfBirth] = useState([]);
    const [placeOfBirthPartner, setPlaceOfBirthPartner] = useState([]);
    const [selectedTag, setSelectedTag] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);

    // Set initial time to 15 minutes (900 seconds)
    const initialTime = 15 * 60; // 15 minutes in seconds
    const [timeRemaining, setTimeRemaining] = useState(initialTime);

    useEffect(() => {
        if (authTokenWebsite === '' || quiz === null) {
            navigate('/');
        }
        else {
            if (+quiz.level !== 108) {
                navigate('/');
            }
        }

        if (quiz.date_of_birth) {

            //get day and month from date
            const splittedDateOfBirth = quiz.date_of_birth.split('-');
            const day = splittedDateOfBirth[2];
            const month = splittedDateOfBirth[1];
            setZodiacSign(getZodiacSign(+day, +month))
        }
        if (quiz.partner_date_of_birth) {

            //get day and month from date
            const splittedDateOfBirth = quiz.partner_date_of_birth.split('-');
            const day = splittedDateOfBirth[2];
            const month = splittedDateOfBirth[1];
            setPartnerZodiacSign(getZodiacSign(+day, +month))
        }
        if (quiz?.gender_text) {
            setGenderText(quiz.gender_text);
        }
        if (quiz?.gender_text_partner) {
            setGenderTextPartner(quiz.gender_text_partner);
        }
        if (quiz?.place_of_birth) {
            setPlaceOfBirth(quiz.place_of_birth);
        }
        if (quiz?.place_of_birth_partner) {
            setPlaceOfBirthPartner(quiz.place_of_birth_partner);
        }
        if (quiz?.trial_amount) {
            setSelectedTag(quiz.trial_amount);
        }
        if (quiz?.plan) {
            setSelectedPlan(quiz.plan);
        }
    }, [authTokenWebsite, quiz]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Set up the interval to update the time every second
        const intervalId = setInterval(() => {
            setTimeRemaining(prevTime => {
                if (prevTime > 0) {
                    return prevTime - 1;
                } else {
                    clearInterval(intervalId); // Stop the interval when time reaches 0
                    return 0;
                    wtLogOut();
                }
            });
        }, 1000); // Update every second (1000ms)

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    // Function to format time in minutes and seconds
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };


    //if loading true then return spinner
    if (isLoading) {
        return <Loading />;
    }


    return (
        <React.Fragment>
            {(authTokenWebsite !== '' && quiz !== null && genderText !== '' && placeOfBirth.length > 0 && selectedPlan !== null) &&
                <Row justify="center">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="responsive-div" style={{ paddingBottom: 0 }}>

                        {/* For Both single and couple both */}
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                            <Row justify="center" style={{ marginBottom: 60 }}>
                                <Col xs={1} sm={1} md={3} lg={3} xl={3}></Col>
                                <Col xs={22} sm={22} md={18} lg={18} xl={18} style={{ backgroundColor: "#F5F5F7", borderRadius: 20, padding: 20 }}>

                                    <Row justify="center" style={{ marginBottom: 10, fontSize: 14 }}>
                                        <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                                        <Col xs={20} sm={20} md={20} lg={20} xl={20} style={{ textAlign: "center", fontWeight: "bold" }}>
                                            <Text>
                                                {t('checkout_2-timer_title')}
                                            </Text>
                                        </Col>
                                        <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 10 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 12 }}>
                                                {t('checkout_2-timer_body')}
                                            </Text>
                                        </Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 10, fontSize: 12 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 12 }}>
                                                {formatTime(timeRemaining)}
                                            </Text>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={1} sm={1} md={3} lg={3} xl={3}></Col>
                            </Row>

                            <Row justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Text className='checkout-title-2'>
                                        {t('checkout_3-intro_title-1')}
                                    </Text>
                                </Col>
                            </Row>

                            <Row justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Text className='checkout-title-2'>
                                        {t('checkout_3-intro_title-2')}
                                    </Text>
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 60 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Text className='checkout-title-2'>
                                        {t('checkout_3-intro_title-3')}
                                    </Text>
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 20 }}>
                                <Col
                                    xs={24} sm={20} md={16} lg={12} xl={10}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: "center"
                                    }}
                                >
                                    <img
                                        style={{ maxWidth: '100%', height: 'auto' }}
                                        src={`${AppConstants.front_base_url}/images/astroguide-sample.png`}
                                        alt="Astroguide Sample"
                                    />
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 100 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 26, fontWeight: "500" }}>
                                        {t('checkout_3-intro_subtitle')}
                                    </Text>
                                </Col>
                            </Row>


                            <Row className='full-background-grey-row'>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>

                                    <Row justify="center">
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 18, fontWeight: "700" }}>
                                                {t('checkout_4-offer_title')}
                                            </Text>
                                        </Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 40 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                                <span dangerouslySetInnerHTML={{
                                                    __html: t('checkout_4-offer_subtitle', {
                                                        pricePersonalizedGuide: wtFormatRoundedValue(
                                                            (+authUserWebsite.language.checkout_type === 1) ? +selectedTag : (applyDiscount) ? selectedPlan.extra_discount_checkout_2 : selectedPlan.discount_checkout_2, currency, false, 2
                                                        ),
                                                    })
                                                }} />
                                            </Text>
                                        </Col>
                                    </Row>


                                    <Row justify="center" style={{ marginBottom: 20 }}>
                                        <Col xs={1} sm={1} md={4} lg={4} xl={4} ></Col>

                                        <Col xs={22} sm={22} md={16} lg={16} xl={16} style={{ textAlign: "center" }}>
                                            <div className="special-offer-thumbnail-container">
                                                <div className="special-offer-thumbnail-content">
                                                    <Row justify="center" style={{ marginBottom: 20 }}>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                                                {t('checkout_5-offerbox_title')}
                                                            </Text>
                                                        </Col>
                                                    </Row>

                                                    <Row justify="center">
                                                        <Col xs={19} sm={19} md={19} lg={19} xl={19} style={{ textAlign: "left" }}>
                                                            {+authUserWebsite.language.checkout_type === 1 &&
                                                                <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                    {t('checkout_5-offerbox_subtitle-1')}
                                                                </Text>
                                                            }
                                                            {+authUserWebsite.language.checkout_type === 0 &&
                                                                <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                    {t('checkout_5-flow-2_special')}
                                                                </Text>
                                                            }
                                                        </Col>
                                                        <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ textAlign: "right" }}>
                                                            <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                {currency + ((+authUserWebsite.language.checkout_type === 0) ? wtFormatRoundedValue((applyDiscount) ? selectedPlan.extra_discount_checkout_2 : selectedPlan.discount_checkout_2, '', false, 2) : wtFormatRoundedValue(+selectedTag, '', false, 2))}
                                                            </Text>
                                                        </Col>
                                                    </Row>

                                                    <hr style={{ width: "100%" }} />

                                                    <Row justify="center">
                                                        {+authUserWebsite.language.checkout_type === 0 &&
                                                            <>
                                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                                                                    <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                        {t('checkout_5-flow-2_regular')}
                                                                    </Text>
                                                                </Col>
                                                                <Col xs={7} sm={7} md={7} lg={7} xl={7} style={{ textAlign: "right" }}>
                                                                    <Text className='upsell-colored-title'>
                                                                        <span dangerouslySetInnerHTML={{
                                                                            __html: t('checkout_5-flow-2_save', {
                                                                                totalDiscountPercentage: wtFormatRoundedValue(applyDiscount ? selectedPlan.extra_discount_rate_checkout_2 : selectedPlan.discount_rate_checkout_2, '', false, 2),
                                                                            })
                                                                        }} />
                                                                    </Text>
                                                                </Col>
                                                            </>
                                                        }

                                                        {+authUserWebsite.language.checkout_type === 1 &&
                                                            <Col xs={19} sm={19} md={19} lg={19} xl={19} style={{ textAlign: "left" }}>
                                                                <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                    {t('checkout_5-offerbox_subtitle-2')}
                                                                </Text>
                                                            </Col>
                                                        }
                                                        <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ textAlign: "right" }}>
                                                            {+authUserWebsite.language.checkout_type === 1 &&
                                                                <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                    {currency + wtFormatRoundedValue(applyDiscount ? selectedPlan.discount : selectedPlan.price, '', false, 2)}
                                                                </Text>
                                                            }
                                                            {+authUserWebsite.language.checkout_type === 0 &&
                                                                <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                    {currency + wtFormatRoundedValue(selectedPlan.price_checkout_2, '', false, 2)}
                                                                </Text>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={1} sm={1} md={4} lg={4} xl={4} ></Col>
                                    </Row>

                                    <Row justify="center">
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Button type="primary" size="large" className="cta-button"
                                                style={{ maxWidth: 300 }}
                                                onClick={() => checkoutClick()}
                                            >
                                                {t('button_getyourguide')}
                                            </Button>
                                        </Col>
                                    </Row>


                                </Col>
                            </Row>



                            <Row justify="center" style={{ marginBottom: 40 }}>
                                <Col xs={24} sm={20} md={18} lg={16} xl={12} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 26, fontWeight: "bold" }}>
                                        {t('checkout_7-benefits_subtitle-1')}
                                    </Text>
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 40 }}>
                                <Col
                                    xs={24} sm={20} md={16} lg={12} xl={10}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: "center"
                                    }}
                                >
                                    <img
                                        style={{ maxWidth: '30%', height: 'auto' }}
                                        src={`${AppConstants.front_base_url}/images/checkout-page-arrow-icon.png`}
                                        alt="Checkout Arrow"
                                    />
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 0 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 18, fontWeight: "600" }}>
                                        {t('checkout_7-benefits_subtitle-2')}
                                    </Text>
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 80 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Text className='checkout-title-2'>
                                        {capitalizeFirstLetter(t(quiz.acheive_goal_final_key ? quiz.acheive_goal_final_key : ''))}
                                    </Text>
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 20 }}>
                                <Col xs={2} sm={2} md={4} lg={4} xl={4}></Col>
                                <Col xs={22} sm={22} md={16} lg={16} xl={16} style={{ textAlign: "left" }}>
                                    <Text style={{ fontSize: 16, fontWeight: "700" }}>
                                        {t('checkout_7-benefits_subtitle-3')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={4} lg={4} xl={4}></Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 10 }}>
                                <Col xs={2} sm={2} md={4} lg={4} xl={4}></Col>
                                <Col xs={22} sm={22} md={16} lg={16} xl={16} style={{ textAlign: "left" }}>
                                    <Text style={{ fontSize: 14, fontWeight: "400" }}>
                                        {t('checkout_7-benefits_subtext-1')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={4} lg={4} xl={4}></Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 0 }}>
                                <Col xs={2} sm={2} md={4} lg={4} xl={4}></Col>
                                <Col xs={22} sm={22} md={16} lg={16} xl={16} style={{ textAlign: "left" }}>
                                    <Text style={{ fontSize: 14, fontWeight: "400" }}>
                                        {t('checkout_7-benefits_subtext-2')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={4} lg={4} xl={4}></Col>
                            </Row>


                            <Row justify="center" style={{ marginBottom: 60 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Button type="primary" size="large" className="cta-button"
                                        style={{ maxWidth: 300 }}
                                        onClick={() => checkoutClick()}
                                    >
                                        {t('button_getyourguide')}
                                    </Button>
                                </Col>
                            </Row>



                            {/* For Single and couple */}

                            <Row className='full-background-grey-row'>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>

                                    <Row justify="center" style={{ marginBottom: 10 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 18, fontWeight: "600" }}>
                                                {t('checkout_8-profile_title')}
                                            </Text>
                                        </Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 0 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 25, fontWeight: "bold" }}>
                                                {t('custom_1a_' + zodiacSign) + " "}
                                                {genderText}
                                            </Text>
                                        </Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 30 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 25, fontWeight: "bold" }}>
                                                {t('checkout_8-profile_subtitle-' + zodiacSign)}
                                            </Text>
                                        </Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 20 }}>
                                        <Col
                                            xs={24} sm={20} md={16} lg={12} xl={10}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                textAlign: "center"
                                            }}
                                        >
                                            <img
                                                style={{ maxWidth: '20%', height: 'auto' }}
                                                src={`${AppConstants.front_base_url}/images/zodiac-${zodiacSign}.png`}
                                                alt="Zodiac Sign"
                                            />
                                        </Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 30 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                                {t('checkout_8-profile_dob')}
                                            </Text>
                                        </Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 30 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 14, fontWeight: "400" }}>
                                                {wtFormatDate(quiz.date_of_birth, "DD.MM.YYYY")}
                                            </Text>
                                        </Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 30 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                                {t('checkout_8-profile_pob')}
                                            </Text>
                                        </Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 30 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 14, fontWeight: "400" }}>
                                                {placeOfBirth[2]}
                                            </Text>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>




                            {quiz.relationship_type === 'couple' &&
                                <React.Fragment>

                                    {/* Partner Details */}
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>

                                            <Row justify="center" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                    <Text style={{ fontSize: 18, fontWeight: "600" }}>
                                                        {t('checkout_9-partnerprofile_title')}
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" style={{ marginBottom: 0 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                    <Text style={{ fontSize: 25, fontWeight: "bold" }}>
                                                        {t('custom_1a_' + partnerZodiacSign) + " "}
                                                        {genderTextPartner}
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                    <Text style={{ fontSize: 25, fontWeight: "bold" }}>
                                                        {t('checkout_8-profile_subtitle-' + partnerZodiacSign)}
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" style={{ marginBottom: 20 }}>
                                                <Col
                                                    xs={24} sm={20} md={16} lg={12} xl={10}
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ maxWidth: '40%', height: 'auto' }}
                                                        src={`${AppConstants.front_base_url}/images/zodiac-${partnerZodiacSign}.png`}
                                                        alt="Zodiac Sign"
                                                    />
                                                </Col>
                                            </Row>

                                            <Row justify="center" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                    <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                                        {t('checkout_8-profile_dob')}
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                    <Text style={{ fontSize: 14, fontWeight: "400" }}>
                                                        {wtFormatDate(quiz.partner_date_of_birth, "DD.MM.YYYY")}
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                    <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                                        {t('checkout_8-profile_pob')}
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                    <Text style={{ fontSize: 14, fontWeight: "400" }}>
                                                        {placeOfBirthPartner[2]}
                                                    </Text>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>



                                    {/* Compatibility */}
                                    <Row className='full-background-grey-row' style={{ marginBottom: 80 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>

                                            <Row justify="center" style={{ marginBottom: 10 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                    <Text style={{ fontSize: 18, fontWeight: "600" }}>
                                                        {t('checkout_10-compatibility_title')}
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" style={{ marginBottom: 0 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                    <Text style={{ fontSize: 25, fontWeight: "bold" }}>
                                                        {t('custom_1a_' + zodiacSign) + " "}
                                                        {t('checkout_10-compatibility_subtitle-1') + " "}
                                                        {t('custom_1a_' + partnerZodiacSign) + " "}
                                                    </Text>
                                                </Col>
                                            </Row>


                                            <Row justify="center" style={{ marginBottom: 20 }}>
                                                <Col
                                                    xs={24} sm={20} md={16} lg={12} xl={10}
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ maxWidth: '20%', height: 'auto', marginRight: "-30px", zIndex: 9999 }}
                                                        src={`${AppConstants.front_base_url}/images/zodiac-${zodiacSign}.png`}
                                                        alt="Zodiac Sign"
                                                    />

                                                    <img
                                                        style={{ maxWidth: '20%', height: 'auto' }}
                                                        src={`${AppConstants.front_base_url}/images/zodiac-${partnerZodiacSign}.png`}
                                                        alt="Zodiac Sign"
                                                    />
                                                </Col>

                                            </Row>

                                            <Row justify="center" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                    <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                                        {t('custom-match_' + zodiacSign + '_' + partnerZodiacSign + '_slogan')}
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                    <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                                        {t('checkout_10-compatibility_subtitle-2')}
                                                    </Text>
                                                </Col>
                                            </Row>

                                            <Row justify="center" style={{ marginBottom: 30 }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                    <Text style={{ fontSize: 24, fontWeight: "bold", color: "#1bba1b" }}>
                                                        {t('custom-match_' + zodiacSign + '_' + partnerZodiacSign + '_%')}
                                                    </Text>
                                                </Col>
                                            </Row>



                                            <Row justify="center" style={{ marginBottom: 10 }}>
                                                <Col xs={1} sm={1} md={3} lg={3} xl={3}></Col>

                                                <Col xs={22} sm={22} md={18} lg={18} xl={18} className='checkout-couple-last-thumbnail'>

                                                    <Row justify="center" style={{ marginBottom: 10, fontSize: 14 }}>
                                                        <Col xs={4} sm={4} md={4} lg={4} xl={4}></Col>
                                                        <Col xs={16} sm={16} md={16} lg={16} xl={16} style={{ textAlign: "center", fontWeight: "bold" }}>
                                                            <Text style={{ color: "#fff" }}>
                                                                {t('checkout_11-access_subtitle-1')}
                                                            </Text>
                                                        </Col>
                                                        <Col xs={4} sm={4} md={4} lg={4} xl={4}></Col>
                                                    </Row>

                                                    <Row justify="center" style={{ marginBottom: 10, fontSize: 14 }}>
                                                        <Col xs={4} sm={4} md={4} lg={4} xl={4}></Col>
                                                        <Col xs={16} sm={16} md={16} lg={16} xl={16} style={{ textAlign: "center", fontWeight: "bold" }}>
                                                            <Text style={{ color: "#fff" }}>
                                                                {t('checkout_11-access_subtitle-2')}
                                                            </Text>
                                                        </Col>
                                                        <Col xs={4} sm={4} md={4} lg={4} xl={4}></Col>
                                                    </Row>
                                                </Col>

                                                <Col xs={1} sm={1} md={3} lg={3} xl={3}></Col>
                                            </Row>


                                            <Row justify="center">
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                    <Button type="primary" size="large" className="checkout-next-guide-button"
                                                        style={{ maxWidth: 300 }}
                                                        onClick={() => checkoutClick()}
                                                    >
                                                        {t('button_getyourguide')}
                                                    </Button>
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row>

                                </React.Fragment>
                            }

                            {/* Last Rows of page */}

                            <Row justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 15, fontWeight: "500" }}>
                                        {t('checkout_12-content_title')}
                                    </Text>
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 50 }}>
                                <Col xs={0} sm={0} md={6} lg={6} xl={6}></Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                        {t('checkout_12-content_subtitle')}
                                    </Text>
                                </Col>
                                <Col xs={0} sm={0} md={6} lg={6} xl={6}></Col>
                            </Row>

                            <Row justify="center">
                                <Col xs={2} sm={2} md={7} lg={7} xl={7}></Col>
                                <Col xs={20} sm={20} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                                    <Text style={{ color: "#5606ef", fontSize: 20, fontWeight: "500" }}>
                                        {t('checkout_12-content_body-1')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={5} lg={5} xl={5}></Col>
                            </Row>

                            <Row justify="center">
                                <Col xs={2} sm={2} md={7} lg={7} xl={7}></Col>
                                <Col xs={20} sm={20} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                                    <Text style={{ color: "#8e00fd", fontSize: 20, fontWeight: "500" }}>
                                        {t('checkout_12-content_body-2')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={5} lg={5} xl={5}></Col>
                            </Row>

                            <Row justify="center">
                                <Col xs={2} sm={2} md={7} lg={7} xl={7}></Col>
                                <Col xs={20} sm={20} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                                    <Text style={{ color: "#e726e1", fontSize: 20, fontWeight: "500" }}>
                                        {t('checkout_12-content_body-3')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={5} lg={5} xl={5}></Col>
                            </Row>

                            <Row justify="center">
                                <Col xs={2} sm={2} md={7} lg={7} xl={7}></Col>
                                <Col xs={20} sm={20} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                                    <Text style={{ color: "#fb3ac2", fontSize: 20, fontWeight: "500" }}>
                                        {t('checkout_12-content_body-4')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={5} lg={5} xl={5}></Col>
                            </Row>

                            <Row justify="center">
                                <Col xs={2} sm={2} md={7} lg={7} xl={7}></Col>
                                <Col xs={20} sm={20} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                                    <Text style={{ color: "#ff4d9c", fontSize: 20, fontWeight: "500" }}>
                                        {t('checkout_12-content_body-5')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={5} lg={5} xl={5}></Col>
                            </Row>

                            <Row justify="center">
                                <Col xs={2} sm={2} md={7} lg={7} xl={7}></Col>
                                <Col xs={20} sm={20} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                                    <Text style={{ color: "#ff4d9c", fontSize: 20, fontWeight: "500" }}>
                                        {t('checkout_12-content_body-6')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={5} lg={5} xl={5}></Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 60 }}>
                                <Col xs={2} sm={2} md={7} lg={7} xl={7}></Col>
                                <Col xs={20} sm={20} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                                    <Text style={{ color: "#ff4d9c", fontSize: 20, fontWeight: "500" }}>
                                        {t('checkout_12-content_body-7')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={5} lg={5} xl={5}></Col>
                            </Row>





                            {/* Testimonials */}
                            <Row className='full-background-grey-row'>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>

                                    <Row justify="center" style={{ marginBottom: 10 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 18, fontWeight: "600" }}>
                                                {t('home_4-testimonials_title')}
                                            </Text>
                                        </Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 0 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 25, fontWeight: "bold" }}>
                                                {t('checkout_13-testimonials_subtitle-1')}
                                            </Text>
                                        </Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 30 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 25, fontWeight: "bold" }}>
                                                {t('checkout_13-testimonials_subtitle-2')}
                                            </Text>
                                        </Col>
                                    </Row>

                                    {/* Carosoule */}
                                    <Row justify="center" style={{ marginBottom: 30 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Carousel />
                                        </Col>
                                    </Row>


                                </Col>
                            </Row>


                            {/* Faqs*/}

                            <Row justify="center" style={{ marginBottom: 0 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 15, fontWeight: "bold" }}>
                                        {t('checkout_14-astrology_title')}
                                    </Text>
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 30 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 24, fontWeight: "bold" }}>
                                        {t('checkout_14-astrology_subtitle')}
                                    </Text>
                                </Col>
                            </Row>


                            <Row justify="center" style={{ marginBottom: 10 }}>
                                <Col xs={2} sm={2} md={7} lg={7} xl={7}></Col>
                                <Col xs={20} sm={20} md={18} lg={18} xl={18} style={{ textAlign: "left" }}>
                                    <Text style={{ fontSize: 16, fontWeight: "700" }}>
                                        {t('checkout_14-astrology_question-1')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 10 }}>
                                <Col xs={2} sm={2} md={7} lg={7} xl={7}></Col>
                                <Col xs={20} sm={20} md={18} lg={18} xl={18} style={{ textAlign: "left" }}>
                                    <Text style={{ fontSize: 12, fontWeight: "500" }}>
                                        {t('checkout_14-astrology_answer-1.1')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 10 }}>
                                <Col xs={2} sm={2} md={7} lg={7} xl={7}></Col>
                                <Col xs={20} sm={20} md={18} lg={18} xl={18} style={{ textAlign: "left" }}>
                                    <Text style={{ fontSize: 12, fontWeight: "500" }}>
                                        {t('checkout_14-astrology_answer-1.2')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 10 }}>
                                <Col xs={2} sm={2} md={7} lg={7} xl={7}></Col>
                                <Col xs={20} sm={20} md={18} lg={18} xl={18} style={{ textAlign: "left" }}>
                                    <Text style={{ fontSize: 12, fontWeight: "500" }}>
                                        {t('checkout_14-astrology_answer-1.3')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                            </Row>


                            <Row justify="center" style={{ marginBottom: 10 }}>
                                <Col xs={2} sm={2} md={7} lg={7} xl={7}></Col>
                                <Col xs={20} sm={20} md={18} lg={18} xl={18} style={{ textAlign: "left" }}>
                                    <Text style={{ fontSize: 16, fontWeight: "700" }}>
                                        {t('checkout_14-astrology_question-2')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 10 }}>
                                <Col xs={2} sm={2} md={7} lg={7} xl={7}></Col>
                                <Col xs={20} sm={20} md={18} lg={18} xl={18} style={{ textAlign: "left" }}>
                                    <Text style={{ fontSize: 12, fontWeight: "500" }}>
                                        {t('checkout_14-astrology_answer-2')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                            </Row>


                            <Row justify="center" style={{ marginBottom: 10 }}>
                                <Col xs={2} sm={2} md={7} lg={7} xl={7}></Col>
                                <Col xs={20} sm={20} md={18} lg={18} xl={18} style={{ textAlign: "left" }}>
                                    <Text style={{ fontSize: 16, fontWeight: "700" }}>
                                        {t('checkout_14-astrology_question-3')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 10 }}>
                                <Col xs={2} sm={2} md={7} lg={7} xl={7}></Col>
                                <Col xs={20} sm={20} md={18} lg={18} xl={18} style={{ textAlign: "left" }}>
                                    <Text style={{ fontSize: 12, fontWeight: "500" }}>
                                        {t('checkout_14-astrology_answer-3.1')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 40 }}>
                                <Col xs={2} sm={2} md={7} lg={7} xl={7}></Col>
                                <Col xs={20} sm={20} md={18} lg={18} xl={18} style={{ textAlign: "left" }}>
                                    <Text style={{ fontSize: 12, fontWeight: "500" }}>
                                        {t('checkout_14-astrology_answer-3.2')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                            </Row>



                            <Row className='full-background-grey-row' style={{ marginBottom: 0 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>

                                    <Row justify="center">
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 18, fontWeight: "700" }}>
                                                {t('checkout_4-offer_title')}
                                            </Text>
                                        </Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 40 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                                <span dangerouslySetInnerHTML={{
                                                    __html: t('checkout_4-offer_subtitle', {
                                                        pricePersonalizedGuide: wtFormatRoundedValue(
                                                            (+authUserWebsite.language.checkout_type === 1) ? +selectedTag : (applyDiscount) ? selectedPlan.extra_discount_checkout_2 : selectedPlan.discount_checkout_2, currency, false, 2
                                                        ),
                                                    })
                                                }} />
                                            </Text>
                                        </Col>
                                    </Row>


                                    <Row justify="center" style={{ marginBottom: 20 }}>
                                        <Col xs={1} sm={1} md={4} lg={4} xl={4} ></Col>

                                        <Col xs={22} sm={22} md={16} lg={16} xl={16} style={{ textAlign: "center" }}>
                                            <div className="special-offer-thumbnail-container">
                                                <div className="special-offer-thumbnail-content">
                                                    <Row justify="center" style={{ marginBottom: 20 }}>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                                                {t('checkout_5-offerbox_title')}
                                                            </Text>
                                                        </Col>
                                                    </Row>

                                                    <Row justify="center">
                                                        <Col xs={19} sm={19} md={19} lg={19} xl={19} style={{ textAlign: "left" }}>
                                                            {+authUserWebsite.language.checkout_type === 1 &&
                                                                <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                    {t('checkout_5-offerbox_subtitle-1')}
                                                                </Text>
                                                            }
                                                            {+authUserWebsite.language.checkout_type === 0 &&
                                                                <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                    {t('checkout_5-flow-2_special')}
                                                                </Text>
                                                            }

                                                        </Col>
                                                        <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ textAlign: "right" }}>
                                                            <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                {currency + ((+authUserWebsite.language.checkout_type === 0) ? wtFormatRoundedValue((applyDiscount) ? selectedPlan.extra_discount_checkout_2 : selectedPlan.discount_checkout_2, '', false, 2) : wtFormatRoundedValue(+selectedTag, '', false, 2))}
                                                            </Text>
                                                        </Col>
                                                    </Row>

                                                    <hr style={{ width: "100%" }} />

                                                    <Row justify="center">
                                                        {+authUserWebsite.language.checkout_type === 0 &&
                                                            <>
                                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                                                                    <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                        {t('checkout_5-flow-2_regular')}
                                                                    </Text>
                                                                </Col>
                                                                <Col xs={7} sm={7} md={7} lg={7} xl={7} style={{ textAlign: "right" }}>
                                                                    <Text className='upsell-colored-title'>
                                                                        <span dangerouslySetInnerHTML={{
                                                                            __html: t('checkout_5-flow-2_save', {
                                                                                totalDiscountPercentage: wtFormatRoundedValue(applyDiscount ? selectedPlan.extra_discount_rate_checkout_2 : selectedPlan.discount_rate_checkout_2, '', false, 2),
                                                                            })
                                                                        }} />
                                                                    </Text>
                                                                </Col>
                                                            </>
                                                        }

                                                        {+authUserWebsite.language.checkout_type === 1 &&
                                                            <Col xs={19} sm={19} md={19} lg={19} xl={19} style={{ textAlign: "left" }}>
                                                                <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                    {t('checkout_5-offerbox_subtitle-2')}
                                                                </Text>
                                                            </Col>
                                                        }
                                                        <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ textAlign: "right" }}>
                                                            {+authUserWebsite.language.checkout_type === 1 &&
                                                                <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                    {currency + wtFormatRoundedValue(applyDiscount ? selectedPlan.discount : selectedPlan.price, '', false, 2)}
                                                                </Text>
                                                            }
                                                            {+authUserWebsite.language.checkout_type === 0 &&
                                                                <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                                    {currency + wtFormatRoundedValue(selectedPlan.price_checkout_2, '', false, 2)}
                                                                </Text>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={1} sm={1} md={4} lg={4} xl={4} ></Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 40 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Button type="primary" size="large" className="cta-button"
                                                style={{ maxWidth: 300 }}
                                                onClick={() => checkoutClick()}
                                            >
                                                {t('button_getyourguide')}
                                            </Button>
                                        </Col>
                                    </Row>


                                    {+authUserWebsite.language.checkout_type === 1 &&
                                        <Row justify="center" style={{ marginBottom: 0 }}>
                                            <Col xs={2} sm={2} md={7} lg={7} xl={7}></Col>
                                            <Col xs={20} sm={20} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
                                                <Text style={{ fontSize: 12, fontWeight: "500" }}>
                                                    {applyDiscount &&
                                                        <span dangerouslySetInnerHTML={{ __html: t('checkout_15-policy-with-popup-discount', { normalDuration: selectedPlan.normal_duration, trialDuration: selectedPlan.trial_duration, subscriptionPriceDiscount: wtFormatRoundedValue(selectedPlan.discount, currency, false, 2), subscriptionPriceRegular: wtFormatRoundedValue(selectedPlan.price, currency, false, 2) }) }} />
                                                    }

                                                    {!applyDiscount &&
                                                        <span dangerouslySetInnerHTML={{ __html: t('checkout_15-policy', { normalDuration: selectedPlan.normal_duration, trialDuration: selectedPlan.trial_duration, subscriptionPriceRegular: wtFormatRoundedValue(selectedPlan.price, currency, false, 2) }) }} />
                                                    }
                                                </Text>
                                            </Col>
                                            <Col xs={2} sm={2} md={7} lg={7} xl={7}></Col>
                                        </Row>
                                    }
                                </Col>
                            </Row>

                        </Col>
                    </Col>
                </Row>


            }
        </React.Fragment >
    );
}

export default Level108Quiz;
