import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../helpers/commons/loader';
import { wtGetFromLocalStorage } from '../../../../helpers/commons/LocalStorage';
import { useNavigate } from 'react-router-dom';
import { wtLogOut } from '../../../../helpers/commons/utils';
import { AppConstants } from '../../../../helpers/commons/constants';

const { Text } = Typography;

const Introduction = () => {
    const { t } = useTranslation('dashboard');
    const navigate = useNavigate();
    const authToken = wtGetFromLocalStorage('authToken', '');
    const authUser = wtGetFromLocalStorage('authUser', null);

    useEffect(() => {
        if (authToken === '' || authUser === null) {
            wtLogOut();
        }
    }, [authToken, authUser]);

    const [isLoading, setIsLoading] = useState(false);


    //if loading true then return spinner
    if (isLoading) {
        return <Loading />;
    }

    return (
        <React.Fragment>
            {/*  check if quiz is not null and token is not empty */}
            {(authToken !== '' && authUser !== null) &&
                <div className='container' >
                    <Row justify="center">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row style={{ marginBottom: 40 }}>
                                <Col xs={0} sm={0} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                    <img
                                        style={{ width: '100%', height: "auto" }}
                                        src={`${AppConstants.front_base_url}/images/app-1-adv-banner.png`}
                                        alt="Adv Banner"
                                    />
                                </Col>
                            </Row>

                            <Row style={{ marginBottom: 40 }}>
                                <Col xs={1} sm={1} md={6} lg={6} xl={6}></Col>
                                <Col xs={22} sm={22} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 24, fontWeight: "bold" }}>
                                        {t('app-static-5.1-intro')}
                                    </Text>
                                </Col>
                                <Col xs={1} sm={1} md={6} lg={6} xl={6}></Col>
                            </Row>

                            <Row style={{ marginBottom: 30 }}>
                                <Col xs={1} sm={1} md={6} lg={6} xl={6}></Col>
                                <Col xs={22} sm={22} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                                    <Text style={{ fontSize: 14 }}>
                                        {t('app-static-5.1-intro-a')}
                                    </Text>
                                </Col>
                                <Col xs={1} sm={1} md={6} lg={6} xl={6}></Col>
                            </Row>

                            <Row style={{ marginBottom: 30 }}>
                                <Col xs={1} sm={1} md={6} lg={6} xl={6}></Col>
                                <Col xs={22} sm={22} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                                    <Text style={{ fontSize: 14 }}>
                                        {t('app-static-5.1-intro-b')}
                                    </Text>
                                </Col>
                                <Col xs={1} sm={1} md={6} lg={6} xl={6}></Col>
                            </Row>

                            <Row style={{ marginBottom: 30 }}>
                                <Col xs={1} sm={1} md={6} lg={6} xl={6}></Col>
                                <Col xs={22} sm={22} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                                    <Text style={{ fontSize: 14 }}>
                                        {t('app-static-5.1-intro-c')}
                                    </Text>
                                </Col>
                                <Col xs={1} sm={1} md={6} lg={6} xl={6}></Col>
                            </Row>

                            <Row style={{ marginBottom: 30 }}>
                                <Col xs={1} sm={1} md={6} lg={6} xl={6}></Col>
                                <Col xs={22} sm={22} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                                    <Text style={{ fontSize: 14 }}>
                                        {t('app-static-5.1-intro-d')}
                                    </Text>
                                </Col>
                                <Col xs={1} sm={1} md={6} lg={6} xl={6}></Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 30 }}>
                                <Col xs={1} sm={1} md={14} lg={14} xl={14}></Col>
                                <Col xs={22} sm={22} md={8} lg={8} xl={8} style={{ textAlign: "center" }}>
                                    <Button type="primary" size="large" className="cta-button"
                                        style={{ maxWidth: 200 }}
                                    // onClick={() => checkoutClick()}
                                    >
                                        {t('app-static-0-consult-button')}
                                    </Button>
                                </Col>
                                <Col xs={1} sm={1} md={2} lg={2} xl={2}></Col>
                            </Row>

                        </Col>
                    </Row>
                </div>
            }
        </React.Fragment >
    );
}

export default Introduction;
