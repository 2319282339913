import React, { Suspense, useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Loading from '../../../helpers/commons/loader';
import { wtGetFromLocalStorage, wtSaveToLocalStorage } from '../../../helpers/commons/LocalStorage';
import { useNavigate } from 'react-router-dom';
import { AppConstants } from '../../../helpers/commons/constants';
import '../StartQuiz/Quiz.css';
import Level107Quiz from './Level107Quiz';
import Level108Quiz from './Level108Quiz';
import { wtApiCall } from '../../../helpers/commons/ApiWrapper';
import { showErrorNotification } from '../../../helpers/commons/notifications';
import { Footer } from 'antd/es/layout/layout';
import { wtFormatRoundedValue } from '../../../helpers/commons/utils';

const Payment = React.lazy(() => import('./Payment')); // Lazy-load Payment component

const { Text } = Typography;

const Checkout = () => {
    const { t } = useTranslation('website');
    const navigate = useNavigate();
    const authTokenWebsite = wtGetFromLocalStorage('authTokenWebsite', '');
    const authUserWebsite = wtGetFromLocalStorage('authUserWebsite', null);
    const quiz = wtGetFromLocalStorage('quiz', null);

    const [plans, setPlans] = useState([]);
    const [showSpecialOfferModal, setShowSpecialOfferModal] = useState(false)
    const [offerData, setOfferData] = useState(null);

    const [selectedTag, setSelectedTag] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);

    const [showPaymentModal, setShowPaymentModal] = useState(false);

    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [applyDiscount, setApplyDiscount] = useState(false);

    useEffect(() => {
        if (authTokenWebsite === '' || quiz === null) {
            navigate('/');
        }
        else {
            if (quiz.level < 107) {
                navigate('/');
            }
        }
    }, [authTokenWebsite, quiz]);

    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if (quiz?.trial_amount) {
            setSelectedTag(quiz.trial_amount);
        }
        if (quiz?.plan) {
            setSelectedPlan(quiz.plan);
        }
        getPaymentPlans();
        getCountries();

        if (quiz.level > 108) {
            navigate('/Upsell')
        }
        if (quiz.level < 106) {
            navigate('/')
        }
    }, []);

    const getPaymentPlans = () => {
        const country = wtGetFromLocalStorage('country', null);

        try {
            setIsLoading(true)
            wtApiCall(
                'plans',
                {
                    service_type: 1,
                    country: country,
                },
                'get',
                (response) => {
                    if (response && response.data) {
                        setPlans(response.data);
                        if (quiz.level === 108) {
                            setSelectedPlan(response.data[0]);
                            const newQuiz = { ...quiz, plan: response.data[0] };
                            wtSaveToLocalStorage('quiz', newQuiz);
                        }
                    }
                    setIsLoading(false)
                },
                (error) => {
                    showErrorNotification(error.message)
                    setIsLoading(false)
                },
                true
            )
        } catch (error) {
            showErrorNotification('Error processing!', error);
            setIsLoading(false)
        }
    }

    const getCountries = () => {
        try {
            setIsLoading(true)
            wtApiCall(
                'countries',
                {},
                'get',
                (response) => {
                    if (response && response.data) {
                        setCountries(response.data);

                        let defaultCountry = wtGetFromLocalStorage('country', null);
                        if (defaultCountry === null) {
                            defaultCountry = response.data.find(country => country.default === 1);
                        }
                        if (defaultCountry) {
                            setSelectedCountry(defaultCountry);
                        }
                    }
                    setIsLoading(false)
                },
                (error) => {
                    showErrorNotification(error.message)
                    setIsLoading(false)
                },
                true
            )
        } catch (error) {
            showErrorNotification('Error processing!', error);
            setIsLoading(false)
        }
    }


    const checkoutClick = () => {

        if (+quiz.level === 107) {
            if (selectedTag === null) {
                showErrorNotification('Please select an amount!');
                return;
            }
            const newQuiz = { ...quiz, level: 108, trial_amount: selectedTag, plan: selectedPlan };
            wtSaveToLocalStorage('quiz', newQuiz);
            navigate('/Checkout')
        }
        if (+quiz.level === 108) {
            // const newQuiz = { ...quiz, level: 109 };
            // wtSaveToLocalStorage('quiz', newQuiz);
            openPaymentModal();
        }
    }

    useEffect(() => {
        let modalShown = false;

        const handleMouseMove = (event) => {
            if (!modalShown) {
                const { clientY } = event;
                const screenHeight = window.innerHeight;

                // Only open special offer modal if payment modal is not open
                if (clientY < screenHeight * 0.01 && !showPaymentModal) {
                    openSpecialOfferModal();
                    modalShown = true;
                }
            }
        };

        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, [showPaymentModal]);

    const openSpecialOfferModal = () => {
        if (!showSpecialOfferModal && !showPaymentModal) {
            setShowSpecialOfferModal(true);
        }
    };


    const openPaymentModal = () => {
        if (!showSpecialOfferModal && !showPaymentModal) {
            setShowPaymentModal(true);
        }
    };

    const closePaymentModal = () => {
        setShowPaymentModal(false);
    };


    const applyDiscountClick = () => {
        setApplyDiscount(true);
        setShowSpecialOfferModal(false);
    }

    //if loading true then return spinner
    if (isLoading) {
        return <Loading />;
    }

    console.log(quiz)

    return (
        <React.Fragment>
            {/*  check if quiz is not null and token is not empty */}
            {(authTokenWebsite !== '' && quiz !== null) &&
                <div className='container' style={{ height: "100vh" }}>
                    <div className='quiz' style={{ background: "#fff" }}>
                        <div className="quiz-header" style={{
                            borderBottom: "1px solid rgb(248, 244, 244)",
                            boxShadow: "rgb(215, 215, 215)",
                            zIndex: 1000,
                            backgroundColor: "#ffff"
                        }}
                        >
                            <Row justify="space-between" align="middle" className="header-row">
                                <Col xs={10} sm={7} md={4} lg={4} xl={4} style={{ marginTop: 5 }}>
                                    <img
                                        height={32}
                                        src={`${AppConstants.front_base_url}/images/astroguide-logo-darkgrey-desktop.png`}
                                        alt="Logo"
                                        className="logo"
                                    />
                                </Col>
                                {/* <Col xs={7} sm={10} md={16} lg={16} xl={16} style={{ marginTop: 5, textAlign: "center" }}>
                                    {applyDiscount && (
                                        <Text style={{ color: "red", fontWeight: "bold" }}>
                                            {Math.round(((+authUserWebsite.language.checkout_type === 1) ? selectedPlan.discount_rate : selectedPlan.discount_rate_checkout_2))}% Discount Applied!
                                        </Text>
                                    )}
                                </Col> */}
                                <Col xs={7} sm={7} md={4} lg={4} xl={4} className="placeholder-column">
                                    <Row justify="center">
                                        <Col xs={0} sm={0} md={4} lg={4} xl={4}></Col>
                                        <Col xs={24} sm={24} md={16} lg={16} xl={16} style={{ textAlign: "center" }}>
                                            <Button type="primary" size="large" className="cta-button"
                                                style={{ margin: 0, fontSize: 12, height: 34 }}
                                                onClick={() => checkoutClick()}
                                            >
                                                {t('button_getyourguide')}
                                            </Button>
                                        </Col>
                                        <Col xs={0} sm={0} md={4} lg={4} xl={4}></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        <div className='quiz-body'>
                            <Row justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                                    {(+quiz.level === 107 && +authUserWebsite.language.checkout_type === 1) &&
                                        <Row>
                                            <Col xs={0} sm={0} md={5} lg={6} xl={7}></Col>
                                            <Col xs={24} sm={22} md={14} lg={12} xl={10} style={{ textAlign: "center" }}>
                                                <Level107Quiz
                                                    quiz={quiz}
                                                    plans={plans}
                                                    currency={selectedCountry?.currency.currency_symbol}
                                                    selectedTag={selectedTag}
                                                    setSelectedTag={setSelectedTag}
                                                    selectedPlan={selectedPlan}
                                                    setSelectedPlan={setSelectedPlan}
                                                    checkoutClick={() => checkoutClick()}
                                                />
                                            </Col>
                                            <Col xs={0} sm={0} md={5} lg={6} xl={7}></Col>
                                        </Row>
                                    }

                                    {(+quiz.level === 108) &&
                                        <Row>
                                            <Col xs={0} sm={0} md={5} lg={6} xl={7}></Col>
                                            <Col xs={24} sm={22} md={14} lg={12} xl={10} style={{ textAlign: "center" }}>
                                                <Level108Quiz
                                                    quiz={quiz}
                                                    currency={selectedCountry?.currency.currency_symbol}
                                                    plans={plans}
                                                    applyDiscount={applyDiscount}
                                                    checkoutClick={() => checkoutClick()}
                                                />
                                            </Col>
                                            <Col xs={0} sm={0} md={5} lg={6} xl={7}></Col>
                                        </Row>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </div>


                    {/* Footer */}
                    <Footer className='checkout-footer'>
                        Copyright © {new Date().getFullYear()} Astro Guide™. All rights reserved.
                        Contact    Terms of Use    Privacy Policy
                    </Footer>


                    {(selectedPlan !== null && +quiz.level === 108) &&
                        <Row justify="center">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center", minHeight: 0 }}>

                                {/* Payment Component Modal */}
                                <Modal
                                    className='payment-modal-parent'
                                    wrapClassName='payment-modal'
                                    width={450}
                                    closable={false}
                                    onCancel={() => closePaymentModal()}
                                    open={showPaymentModal}
                                    footer={[]}
                                >
                                    <Suspense fallback={<Loading />}>
                                        <Payment currency={selectedCountry?.currency.currency_symbol} selectedPlan={selectedPlan} selectedTag={selectedTag}
                                            countries={countries} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry}
                                            applyDiscount={applyDiscount} authUserWebsite={authUserWebsite}
                                        />
                                    </Suspense>

                                </Modal>


                                {/* Discount Modal */}
                                <Modal
                                    className='special-offer-modal-parent'
                                    wrapClassName='special-offer-modal'
                                    width={400}
                                    closable={false}
                                    onCancel={() => setShowSpecialOfferModal(false)}
                                    open={showSpecialOfferModal}
                                    footer={[
                                        <Row key="footer-row" justify="center" style={{ marginBottom: 10 }}>
                                            <Col key="footer-col-2" xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                <Button type="primary" size="large" className="cta-button"
                                                    style={{ margin: 0, fontSize: 12, height: 34 }}
                                                    onClick={() => applyDiscountClick()}
                                                >
                                                    {t('button_getyourguide')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    ]}
                                >
                                    <Row justify="center" style={{ marginBottom: 10, marginTop: -10 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <img
                                                height={28}
                                                src={`${AppConstants.front_base_url}/images/astroguide-logo-darkgrey-desktop.png`}
                                                alt="Logo"
                                                className="logo"
                                            />
                                        </Col>
                                    </Row>
                                    <Row justify="center" style={{ marginBottom: 10, marginTop: 0 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            <Text style={{ fontSize: 22, fontWeight: "bold" }}>
                                                {`${t('payment_2-discount_title')}`}
                                            </Text>
                                        </Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 10, marginTop: 0 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            {+authUserWebsite.language.checkout_type === 1 &&
                                                <Text style={{ fontSize: 26, fontWeight: "bold" }} className='checkout-title-2'>
                                                    <span dangerouslySetInnerHTML={{ __html: t('payment_2-discount_subtitle-1', { subscriptionPriceDiscountRate: wtFormatRoundedValue(selectedPlan.discount_rate, '', false, 0), }) }} />
                                                </Text>
                                            }
                                            {+authUserWebsite.language.checkout_type === 0 &&
                                                <Text style={{ fontSize: 26, fontWeight: "bold" }} className='checkout-title-2'>
                                                    <span dangerouslySetInnerHTML={{ __html: t('payment-flow-2_discount_subtitle-1') }} />
                                                </Text>
                                            }
                                        </Col>
                                    </Row>


                                    <Row justify="center" style={{ marginBottom: 5 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            {+authUserWebsite.language.checkout_type === 1 &&
                                                <Text style={{ fontSize: 12, fontWeight: "bold", textAlign: "center" }}>
                                                    <span dangerouslySetInnerHTML={{ __html: t('payment_2-discount_subtitle-2', { trialDuration: selectedPlan.trial_duration, subscriptionPriceTrial: wtFormatRoundedValue(+selectedTag, selectedCountry?.currency.currency_symbol, false, 2) }) }} />
                                                </Text>
                                            }
                                        </Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 5 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                            {+authUserWebsite.language.checkout_type === 1 &&
                                                <Text style={{ fontSize: 12, fontWeight: "bold", textAlign: "center" }}>
                                                    <span dangerouslySetInnerHTML={{
                                                        __html: t('payment_2-discount_subtitle-3', {
                                                            subscriptionPriceDiscount: wtFormatRoundedValue(selectedPlan.discount, selectedCountry?.currency.currency_symbol, false, 2),
                                                            subscriptionPriceRegular: wtFormatRoundedValue(selectedPlan.price, selectedCountry?.currency.currency_symbol, false, 2)
                                                        })
                                                    }} />
                                                </Text>
                                            }
                                            {+authUserWebsite.language.checkout_type === 0 &&
                                                <Text style={{ fontSize: 12, fontWeight: "bold", textAlign: "center" }}>

                                                    <span dangerouslySetInnerHTML={{
                                                        __html: t('payment-flow-2_discount_subtitle-2', {
                                                            subscriptionPriceExtraDiscount: wtFormatRoundedValue(selectedPlan.extra_discount_checkout_2, selectedCountry?.currency.currency_symbol, false, 2),
                                                            subscriptionPriceDiscount: wtFormatRoundedValue(selectedPlan.discount_checkout_2, selectedCountry?.currency.currency_symbol, false, 2)
                                                        })
                                                    }} />
                                                </Text>
                                            }
                                        </Col>
                                    </Row>
                                </Modal>
                            </Col>
                        </Row>
                    }
                </div>
            }
        </React.Fragment >
    );
}

export default Checkout;
