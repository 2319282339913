import { lzIsArray } from "./ApiWrapper";
import { all_numeric } from "./regular_expressions/specific_format";
import { wtSaveToLocalStorage, wtGetFromLocalStorage } from "./LocalStorage";
import { wtApiCall } from '../../helpers/commons/ApiWrapper';
import { currentDateTime } from '../../helpers/commons/moment';
import { AppConstants } from "./constants";
import axios from "axios";
import { showErrorNotification } from "./notifications";
import { useNavigate } from "react-router-dom";

export const isObject = (object) => {
  return object != null && typeof object === 'object';
}

export const deepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}

export const wtFormatMoney = (val, curr, acc, decimals = 2) => {
  // Make sure its a number and not a string
  val = +val;

  // Get the sign separately so that it can be printed before currency symbol (- USD 123) instead of after (USD -123)
  const sign = val < 0 ? '- ' : '';
  if ('- ' === sign) {
    val = -val;
  }

  // Get rid of extra decimals
  const str = val.toFixed(decimals);

  // Make the formatted number part
  const num = str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Print as accounting value if opted and if its a credit value
  if (acc && sign !== '') {
    return '(' + curr + '' + num + ')';
  }

  // Return the final formatted string
  return sign + curr + ' ' + num;
};

export const wtFormatMoneyRoundData = (val, curr = "", acc = "") => {
  // Make sure its a number and not a string
  val = +val;

  // Get the sign separately so that it can be printed before currency symbol (- USD 123) instead of after (USD -123)
  const sign = val < 0 ? '- ' : '';
  if ('- ' === sign) {
    val = -val;
  }

  // Get rid of extra decimals
  const str = val.toFixed(0);

  // Make the formatted number part
  const num = str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Print as accounting value if opted and if its a credit value
  if (acc && sign !== '') {
    return '(' + curr + ' ' + num + ')';
  }

  // Return the final formatted string
  return sign + curr + ' ' + num;
};

export const wtCastNumberWithDecimal = (val) => {
  // If value not defined then 0
  if (!val) {
    return 0;
  }

  let splitedString = "" + (val);
  let decimal_part = "";

  splitedString = splitedString.split(".");
  if (splitedString.length > 1) {
    decimal_part = "." + all_numeric(splitedString[1])
  }

  // Find numeric value
  const temp = +splitedString[0];

  // If not a number then 0
  if (isNaN(temp)) {
    return 0;
  }

  // If numeric value but ending with dot (.) means user might be in the middle of typeing a floating number
  // then return including the decimal at the end, else return the numeric value
  return splitedString.length > 1 ? temp + decimal_part : temp;
};

/**
 * Call logged in data loader.
 * Checks if its already called then does not call it.
 *
 * @param boolean forceLoad
 */

export const wtLogOut = () => {

  // Save logout to local storage
  wtSaveToLocalStorage('authUser', null);
  wtSaveToLocalStorage('authToken', '');
  wtSaveToLocalStorage('remember', false);
  wtSaveToLocalStorage('idle_timeout', '');
  wtSaveToLocalStorage('configurations', null);
  wtSaveToLocalStorage('authUserWebsite', null);
  wtSaveToLocalStorage('authTokenWebsite', '');
  wtSaveToLocalStorage('quiz', null);
  wtSaveToLocalStorage('quizDashboard', null);
  wtSaveToLocalStorage('country', null);
  wtSaveToLocalStorage('language', null);
  wtSaveToLocalStorage('authUserUpsell', null);
  wtSaveToLocalStorage('authUserSubscription', null);


}

export class SumObjectArray extends Array {
  sum(key) {
    return this.reduce((a, b) => a + (b[key] || 0), 0);
  }
}

export const wtBytesToSize = (bytes) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return wtRoundValue(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

export const wtTypeConvert = (type, value) => {
  if ('boolean' === type) {
    if (true === value || false === value) {
      return value;
    }

    return 1 === +value;
  }

  // TODO: Handle other types if required

  return value;
};

export const validate_number = (num) => {
  if (isNaN(num)) {
    return 0;
  }
  return num;
}

export const wtRepeatNode = (node, times) => {
  return new Array(times).fill(node).map((e, i) => node);
};

export const wtRepeatTableCell = (times) => {
  return wtRepeatNode('<td>&nbsp;</td>', times);
};

export const wtFormatRoundedValue = (val, curr, acc, decimals = 2) => {
  // Make sure its a number and not a string
  val = +val;

  // Get the sign separately so that it can be printed before currency symbol (- USD 123) instead of after (USD -123)
  const sign = val < 0 ? '- ' : '';
  if ('- ' === sign) {
    val = -val;
  }

  //set decimals
  let roundTo = "1";
  for (let i = 0; i < decimals; i++) {
    roundTo = roundTo + "0";

  }

  // calculate value according to decimals
  const str = '' + (Math.round((+val + Number.EPSILON) * +roundTo) / +roundTo);

  // Make the formatted number part
  const num = str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Print as accounting value if opted and if its a credit value
  if (acc && sign !== '') {
    return '(' + curr + (curr === '' ? '' : ' ') + num + ')';
  }

  // Return the final formatted string
  return sign + curr + ' ' + num;
};

/**
 * Get a positive number and optionally limit to a max number.
 * 
 * @param  decimal 
 * @param decimal max 
 * 
 * @returns decimal
 */
export const wtGetPositiveNumber = (val, max) => {
  let num = +val;

  if (num < 0) {
    num = 0;
  }

  if (typeof max !== 'undefined') {
    if (num > max) {
      num = max;
    }
  }

  return num;
};

export const wtRoundNumberToSpecifedDigits = (value, digits = 2) => {
  let decimalDigits = '';
  while (decimalDigits.length < digits) {
    decimalDigits = decimalDigits + '0';
  }
  decimalDigits = +("1" + decimalDigits);
  return Math.round((+value + Number.EPSILON) * decimalDigits) / decimalDigits;
}

export const wtIsArrayEqual = (array1, array2) => {
  if (typeof array1 === 'undefined' || typeof array2 === 'undefined') {
    return false;
  }

  // only comes here if both arrays are set

  const json1 = JSON.stringify(array1);
  const json2 = JSON.stringify(array2);
  // console.log(json1, json2, json1 === json2);
  return json1 === json2;
}

export const wtRoundValue = (value, decimals = 3) => {

  let roundTo = "1";
  for (let i = 0; i < decimals; i++) {
    roundTo = roundTo + "0";
  }
  // calculate value according to decimals
  return (Math.round((+value + Number.EPSILON) * +roundTo) / +roundTo);
}

export const wtSafeDivide = (num, denom) => {
  return num / (0 === denom ? 1 : denom);
}

export const wtNeutralizeJSRoundingIssue = (num) => {
  return +(num.toFixed(12));
}

/**
 * Add/Remove a value from an array.
 * 
 * @param {[]} arr 
 * @param {boolean} add 
 * @param {number|Array} val 
 * 
 * @returns []
 */
export const wtArrayAddOrRemove = (arr, add, val) => {
  const ret = arr.slice();

  if (add) {
    if (lzIsArray(val)) {
      ret.push(...val);
    } else {
      ret.push(val);
    }

    return ret;
  }

  if (lzIsArray(val)) {
    for (const v of val) {
      const dex = ret.indexOf(v);
      if (dex < 0) {
        continue;
      }

      ret.splice(dex, 1);
    }
  } else {
    const dex = ret.indexOf(val);
    if (dex < 0) {
      return ret;
    }

    ret.splice(dex, 1);
  }

  return ret;
};


export const wtLoadLoggedInData = () => {

  //Get current user
  const authUser = wtGetFromLocalStorage('authUser', null);

  // Get dispatch from redux store
  const reduxDispatch = window.reduxStore.dispatch;

  wtApiCall(
    'logged-in-data',
    {},
    'get',
    (result) => {

      // If already loggedIn then compare password
      if (authUser) {
        const userPassFromLocalStorage = authUser?.password;
        const userPassFromRequest = result.data?.user.password;
        if (userPassFromLocalStorage !== userPassFromRequest) {
          wtLogOut();
          return;
        }
      }
      // Dispatch login data to redux store
      reduxDispatch({
        type: 'set',
        loggedInAttempt: true,
        authUser: result.data.user,
        authToken: result.data.token,
        idle_timeout: currentDateTime(),
      });

      // Save login data to local storage
      wtSaveToLocalStorage('authUser', result.data.user)
      wtSaveToLocalStorage('authToken', result.data.token)
      wtSaveToLocalStorage('idle_timeout', currentDateTime());
      wtSaveToLocalStorage('configurations', result.data.configurations)

    }
  );
};





export const getZodiacSign = (selectedDay, selectedMonth) => {
  const day = +selectedDay;
  const month = +selectedMonth;

  if ((month === 1 && day >= 20) || (month === 2 && day <= 18)) {
    return "aquarius";
  } else if ((month === 2 && day >= 19) || (month === 3 && day <= 20)) {
    return "pisces";
  } else if ((month === 3 && day >= 21) || (month === 4 && day <= 19)) {
    return "aries";
  } else if ((month === 4 && day >= 20) || (month === 5 && day <= 20)) {
    return "taurus";
  } else if ((month === 5 && day >= 21) || (month === 6 && day <= 20)) {
    return "gemini";
  } else if ((month === 6 && day >= 21) || (month === 7 && day <= 22)) {
    return "cancer";
  } else if ((month === 7 && day >= 23) || (month === 8 && day <= 22)) {
    return "leo";
  } else if ((month === 8 && day >= 23) || (month === 9 && day <= 22)) {
    return "virgo";
  } else if ((month === 9 && day >= 23) || (month === 10 && day <= 22)) {
    return "libra";
  } else if ((month === 10 && day >= 23) || (month === 11 && day <= 21)) {
    return "scorpio";
  } else if ((month === 11 && day >= 22) || (month === 12 && day <= 21)) {
    return "sagittarius";
  } else if ((month === 12 && day >= 22) || (month === 1 && day <= 19)) {
    return "capricorn";
  }
};


export const capitalizeFirstLetter = (text) => {
  return text
    .toLowerCase()  // Convert the entire sentence to lowercase first
    .split(' ')     // Split the sentence into words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize first letter
    .join(' ');
};


// Function to get place name using Nominatim API
export const getPlaceName = async (lat, lng, type = 1) => {
  try {
    const response = await axios.get(
      `${AppConstants.map_url_place_name}&lat=${lat}&lon=${lng}`
    );
    const data = response.data;
    if (type === 2) {
      return data.address.country_code;
    }
    const placeName = `${(data.address.city !== undefined) ? data.address.city : ""}, ${data.address.country}`;
    return placeName;
  } catch (error) {
    console.error('Error fetching place name:', error);
    return 'Error fetching place name';
  }
};


export const getSignedURL = async (authUser, type) => {

  if (!authUser) {
    showErrorNotification("User Not found!");
    return null; // Return null if no user is found
  }

  // Return a promise that resolves with the signed URL
  return new Promise((resolve, reject) => {
    wtApiCall(
      'generate-signed-url',
      {
        'userId': authUser.id,
        'type': type
      },
      'get',
      (result) => {
        // Resolve with the signed URL on success
        resolve(result); // Assuming the API response contains the signed URL
      },
      {
        responseType: 'blob', // Handle binary data if downloading PDF
      },
      (error) => {
        reject(error); // Reject in case of an error
      }
    );
  });
};


export const downloadPdf = async (type) => {

  // Get current user
  const authUser = wtGetFromLocalStorage('authUser', null);
  const authToken = wtGetFromLocalStorage('authToken', '');
  const idleTimeout = wtGetFromLocalStorage('idle_timeout', currentDateTime());
  let data = {};

  const headers = {
    'Content-type': 'application/json',
  };

  if (authToken && authToken !== '') {
    headers.Authorization = 'Bearer ' + authToken;
  }


  data._idleTimeOut = idleTimeout;
  data._currentDateTime = currentDateTime();
  data.is_website_call = false;

  try {
    axios({
      url: AppConstants.api_base_url + 'download-pdf/' + authUser.id + '/' + type, // replace with your backend URL
      method: 'GET',
      headers: headers,
      data: data,
      responseType: 'blob', // important for downloading files
    })
      .then((response) => {
        // Extract filename from Content-Disposition header
        const contentDisposition = response.headers['content-disposition'] || response.headers['Content-Disposition'];
        let filename = 'download.pdf'; // Fallback filename

        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="(.+)"/);
          if (filenameMatch && filenameMatch.length === 2) {
            filename = filenameMatch[1]; // Use the actual filename from the backend
          }
        }

        // Create a new Blob object with the binary data
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename; // Use the filename extracted from the header
        link.click(); // Trigger the download
      })
      .catch((error) => {
        console.error('Error downloading the PDF:', error);
      });
  } catch (error) {
    console.error('Error fetching signed URL', error);
  }
};