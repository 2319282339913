import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Typography, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { wtGetFromLocalStorage, wtSaveToLocalStorage } from '../../../helpers/commons/LocalStorage';
import { useNavigate } from 'react-router-dom';
import { showErrorNotification } from '../../../helpers/commons/notifications';
import '../StartQuiz/Quiz.css';
import { wtFormatRoundedValue } from '../../../helpers/commons/utils';

const { Title, Text } = Typography;

const Level107Quiz = ({ quiz, plans, currency, selectedTag, setSelectedTag, selectedPlan, setSelectedPlan, checkoutClick }) => {
    const { t } = useTranslation('website');
    const navigate = useNavigate();
    const authTokenWebsite = wtGetFromLocalStorage('authTokenWebsite', '');


    useEffect(() => {
        if (authTokenWebsite === '' || quiz === null) {
            navigate('/');
        }
        else {
            if (+quiz.level !== 107) {
                navigate('/');
            }
        }
    }, [authTokenWebsite, quiz]);


    const handleTagClick = (tag) => {
        setSelectedTag(tag.trial_price);
        setSelectedPlan(tag);
    };

    return (
        <React.Fragment>
            {/*  check if quiz is not null and token is not empty */}
            {(authTokenWebsite !== '' && quiz !== null && +quiz.level === 107 && plans.length > 0) &&
                <Row justify="center">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="responsive-div">

                        {/* For Both single */}
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row justify="center" style={{ marginBottom: 40 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Title level={4} className='quiz-level-2-title'>
                                        <span dangerouslySetInnerHTML={{ __html: t('trialprice_1-matters_title') }} />
                                    </Title>
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 10, fontSize: 14 }}>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                                <Col xs={20} sm={20} md={20} lg={20} xl={20} style={{ textAlign: "center" }}>
                                    <Text>
                                        {t('trialprice_1-matters_subtitle-1')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 10, fontSize: 14 }}>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                                <Col xs={20} sm={20} md={20} lg={20} xl={20} style={{ textAlign: "center", fontWeight: "bold" }}>
                                    <Text>
                                        {t('trialprice_1-amount')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 60, fontSize: 14, textAlign: "center" }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <div style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
                                        {plans.map((tag, index) => (
                                            <React.Fragment key={index}>
                                                <Tag
                                                    key={index}
                                                    className={`selectable-tag ${+selectedTag === +tag.trial_price ? 'selected' : ''}`}
                                                    onClick={() => handleTagClick(tag)}
                                                >
                                                    {currency + wtFormatRoundedValue(+tag.trial_price, '', false, 2)}
                                                </Tag>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 10, fontSize: 14 }}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Row justify="center" style={{ marginBottom: 10, fontSize: 14 }}>
                                        <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                                        <Col xs={22} sm={22} md={22} lg={22} xl={22} style={{ textAlign: "left" }}>
                                            <Text className='checkout-page-title'>
                                                {t('trialprice_5-cost_title')}
                                            </Text>
                                        </Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 10, fontSize: 14 }}>
                                        <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                                        <Col xs={22} sm={22} md={22} lg={22} xl={22} style={{ textAlign: "left" }}>
                                            <Text>
                                                <span dangerouslySetInnerHTML={{ __html: t('trialprice_5-cost_body') }} />

                                            </Text>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Row justify="center" style={{ marginBottom: 10, fontSize: 14 }}>
                                        <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                                        <Col xs={22} sm={22} md={22} lg={22} xl={22} style={{ textAlign: "left" }}>
                                            <Text className='checkout-page-title'>
                                                {t('trialprice_6-contribute_title')}
                                            </Text>
                                        </Col>
                                    </Row>

                                    <Row justify="center" style={{ marginBottom: 10, fontSize: 14 }}>
                                        <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                                        <Col xs={22} sm={22} md={22} lg={22} xl={22} style={{ textAlign: "left" }}>
                                            <Text>
                                                <span dangerouslySetInnerHTML={{ __html: t('trialprice_6-contribute_body') }} />
                                            </Text>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>


                            <Row justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Button type="primary" size="large" className="cta-button"
                                        style={{ maxWidth: 400 }}
                                        onClick={() => checkoutClick()}
                                    >
                                        {t('button_getyourguide')}
                                    </Button>
                                </Col>
                            </Row>

                        </Col>
                    </Col>
                </Row>
            }
        </React.Fragment >
    );
}

export default Level107Quiz;
