import { Col, Row, Carousel as CarouselComponent, Card, Rate } from 'antd';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import './Carousel.css';

const Carousel = () => {
    const { t } = useTranslation('website');
    const carouselRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);

    const testimonials = [
        {
            name: t('reviews_1-sample_title'),
            rating: t('reviews_1-sample_rating'),
            review: t('reviews_1-sample_body'),
        },
        {
            name: t('reviews_2-sample_title'),
            rating: t('reviews_2-sample_rating'),
            review: t('reviews_2-sample_body'),
        },
        {
            name: t('reviews_3-sample_title'),
            rating: t('reviews_3-sample_rating'),
            review: t('reviews_3-sample_body'),
        },
        {
            name: t('reviews_4-sample_title'),
            rating: t('reviews_4-sample_rating'),
            review: t('reviews_4-sample_body'),
        },
        {
            name: t('reviews_5-sample_title'),
            rating: t('reviews_5-sample_rating'),
            review: t('reviews_5-sample_body'),
        }
    ];

    const handleBeforeChange = (from, to) => {
        setCurrentSlide(to);
    };

    const next = () => {
        carouselRef.current.next();
    };

    const prev = () => {
        carouselRef.current.prev();
    };


    return (
        <Row justify="center">
            <Col xs={24} sm={24} md={12} lg={10} xl={8} style={{ textAlign: "left", margin: '0 auto', padding: 10 }}>
                <CarouselComponent
                    ref={carouselRef}
                    beforeChange={handleBeforeChange}
                    dots={false}
                    slidesToShow={1.5}
                    slidesToScroll={1}
                    infinite={false}
                    centerMode={false}
                >
                    {testimonials.map((testimonial, index) => (
                        <div key={index} style={{ display: 'flex', justifyContent: 'left' }}>
                            <Card
                                bordered={false}
                                className="gradient-border-card"
                                style={{
                                    width: '95%',
                                    height: '250px', // Set a fixed height
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    borderRadius: 20
                                }}
                            >
                                <Rate disabled defaultValue={testimonial.rating} style={{ color: "black", fontSize: 12 }} />
                                <p style={{ fontSize: 14, marginBottom: 5, marginTop: 5, fontWeight: "bold" }}>{testimonial.name}</p>
                                <p style={{ fontSize: 13 }}>{testimonial.review}</p>
                            </Card>
                        </div>
                    ))}
                </CarouselComponent>
                <Row justify="center" align="middle" style={{ marginTop: 20 }}>
                    <CaretLeftFilled className="arrow" onClick={prev} style={{ marginRight: 15, color: "#999999" }} />
                    {testimonials.map((_, index) => (
                        <div
                            key={index}
                            className={`indicator ${currentSlide === index ? 'active' : ''}`}
                            onClick={() => carouselRef.current.goTo(index)}
                            style={{
                                width: '10px',
                                height: '10px',
                                backgroundColor: currentSlide === index ? '#999999' : '#ccc',
                                borderRadius: '50%',
                                margin: '0 5px',
                                cursor: 'pointer',
                            }}
                        ></div>
                    ))}
                    <CaretRightFilled className="arrow" onClick={next} style={{ marginLeft: 15, color: "#999999" }} />
                </Row>
            </Col>
        </Row>
    );
};

export default Carousel;
